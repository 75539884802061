export enum ECookieName {
  /**
   * Stores the last url before redirecting to login
   */
  BEFORE_LOGIN_URL = 'mapped:before_login_url',
}

export enum EStorageKey {
  /**
   * Stores the current auth token
   */
  AUTH_TOKEN = 'mapped:auth_token',
  /**
   * Stores Refresh Token
   */
  REFRESH_TOKEN = 'mapped:refresh_token',
}

export enum EStepToken {
  IDLE = 'IDLE',
  NEW = 'NEW',
  GENERATED = 'GENERATED',
}
