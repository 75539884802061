import Cookies, { CookieSetOptions } from 'universal-cookie'
import { ECookieName } from '../types/general'

export function setCookie(
  name: ECookieName,
  value: string,
  options?: CookieSetOptions
) {
  return new Cookies().set(name, value, options)
}

export function getCookie(name: ECookieName) {
  return new Cookies().get(name)
}

export function removeCookie(name: ECookieName, options?: any) {
  return new Cookies().remove(name, options)
}

export function getAllCookies() {
  return new Cookies().getAll()
}
